.order-now{
    width: 100%;
    padding: 60px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
}

.order-now h2{
    color: #431c0d;
    font-size: 20px;
}

.order-now h1{
    color: #ff0000;
    font-size: 40px;
    margin: 0;
}

.order-now h3{
    color: #111111;
}

.order-now .online{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 20px 60px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 10px;
}

.order-now .online img{
    width: 150px;
}


@media (max-width: 1200px) {}

@media (max-width: 768px) {
    .order-now{
        padding: 60px 30px;
    }

    .order-now h2{
        text-align: center;
    }

    .order-now h1{
        text-align: center;
        font-size: 30px;
        padding: 20px;
        background-color: #ff0000;
        color: #ffffff;
        border-radius: 10px;
    }

    .order-now h3{
        text-align: center;
    }
}


@media (max-width: 480px) {}