.home-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url('../../images/ivory-bg.jpg');
    background-position: center;
    background-size: cover;
    padding: 50px 80px;
}




.home-container .home-container-text{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}



.home-container .home-container-text h1{
    color: #ff0000;
    font-size: 40px;
    margin: 0;
}

.home-container .home-container-text h2{
    color: #ffffff;
    font-size: 18px;
}

.line{
    width: 80px;
    padding-bottom: 30px;
    padding-top: 20px;
}

.homelogo{
    width: 100px;
    border-radius: 50%;
}
.home-container .home-container-text .buttonsDiv .redBtn{
    padding: 15px 30px;
    background-color: #ff0000;
    border-radius: 50px;
    color: #ffffff;;
    border: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 2px solid #ff0000;
    cursor: pointer;
}

.home-container .home-container-text .buttonsDiv .redBtn a{
    text-decoration: none;
    color: #ffffff;
}

.home-container .home-container-text .buttonsDiv .whiteBtn a{
    text-decoration: none;
    color: #ff0000;
}


.home-container .home-container-text .buttonsDiv .whiteBtn{
    padding: 15px 30px;
    background-color: #ffffff;
    border-radius: 50px;
    color: #ff0000;;
    border: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 2px solid #ff0000;
    cursor: pointer;
}

.home-container .home-container-text .buttonsDiv{
    display: flex;
    gap: 20px;
}


.home-container .home-container-text p{
    color: #ffffff;
    font-size: 14px;
    text-align: left;
    width: 80%;
    padding: 20px 0;
}



.home-container .home-image{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.home-container .home-image img{
    width: 60%;
    animation: rotateAnimation 5s linear infinite;
}

@keyframes rotateAnimation {
	from {transform: rotateY(45deg);}
	to {transform: rotateY(225deg);}
}

.about-container{
    width: 100vw;
    padding: 40px 80px;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.about-image{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-image img{
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    border-bottom: 5px solid #ff0000;
    border-left: 5px solid #ff0000;
}

.about-text{
    width: 50%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

}

.about-text h2{
    font-size: 30px;
    font-weight: bolder;
    color: #ff0000;
    text-align: left;
}

.about-text p{
    font-size: 16px;
    text-align: left;
}

.about-text h3{
    font-size: 20px;
    font-weight: bolder;
    color: #be912c;
}

.about-text img{
    text-align: center;
}


.points{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
}

.point{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    width: 100%;
}

.point i{
    font-size: 30px;
    padding: 10px;
    border: 2px solid #ff0000;
    color: #ff0000;
}

.point h3{
    font-size: 16px;
    font-weight: bold;
    color: #ff0000;
}

.point p{
    font-size: 14px;
    text-align: center;
    color: #000000;
}


.why-us{
    width: 100%;
    padding: 80px 80px;
    padding-top: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 40px;
}

.why-us-1{
    width: 30%;
    padding: 40px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-top: 5px solid #ff0000;
    border-bottom: 5px solid #ff0000;
}

.why-us-1 i{
    color: #ff0000;
    font-size: 20px;
    border: 2px solid #ff0000;
    padding: 15px;
    margin-bottom: 10px;
}

.why-us-1 h3{
    font-size: 16px;
    font-weight: bold;
    color: #000000;
}

.why-us-1 p{
    font-size: 14px;
    text-align: center;
    color: #000000;
}

.treatmentsWrapper{
    background-color: #f1f0f0;
    padding: 60px 0;
}

.treatments{
    width: 100vw;
    padding: 40px 80px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 40px;
    flex-wrap: wrap;
}

.knowMore{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.knowMore button{
    padding: 15px 30px;
    background-color: #ff0000;
    border-radius: 50px;
    color: #ffffff;;
    border: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 2px solid #ff0000;
    cursor: pointer;
}

.heading{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
}

.choose{
    padding: 40px;
}

.heading h2{
    font-size: 30px;
}

.heading img{
    width: 50px;
}

.heading p{
    font-size: 14px;
}



@media (max-width: 1200px) {

    .home-container-text h2{
        display: none;
    }
    .home-container-text img{
        display: none;
    }

    .treatments .treatment{
        width: 30%;
    }

    .why-us .why-us-1 h3{
        text-align: center;
    }
}

@media (max-width: 768px) {
    .home-container-text h2{
        display: block;
    }
    .home-container-text img{
        display: block;
    }
    .home-container{
        flex-direction: column;
        padding: 60px 30px;
        align-items: center;
        justify-content: center;
    }

    .home-container .home-container-text{
        width: 100%;
        align-items: center;
        padding: 0;
    }

    .home-container .home-container-text h1{
        text-align: center;
    }

    .home-container .home-container-text p{
        text-align: center;
        width: 90%;
    }

    .home-container .home-image{
        width: 50%;
        justify-content: center;
        align-items: center;
    }

    .home-container .home-container-text .buttonsDiv{
        flex-direction: column;
        gap: 20px;
    }

    home-container .home-container-text .buttonsDiv button{
        width: 100%;
    }


    .about-container{
        flex-direction: column;
        padding: 60px 30px;
        gap: 0;
    }

    .about-container .about-image{
        width: 100%;
    }

    .about-container .about-text{
        width: 100%;
        padding: 0;
        padding-top: 20px;
        align-items: center;
        justify-content: center;
    }

    .about-container .about-text h2{
        text-align: center;
        font-size: 30px;
    }

    .about-container .about-text p{
        text-align: center;
    }

    .about-container .about-text .points .point{
        flex-direction: column;
        gap: 0;
        padding: 20px;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .why-us{
        flex-direction: column;
        padding: 60px 30px;
    }

    .why-us-1{
        width: 100%;
    }

    .heading{
        flex-direction: column;
        padding: 0;
    }


    .treatments{
        flex-direction: column;
        padding: 60px 30px;
        gap: 60px;
    }

    .treatments .treatment{
        width: 100%;
    }
}


@media (max-width: 480px) {


}