.page-banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url('../../images/ivory-bg.jpg');
    background-position: center;
    background-size: cover;
    padding: 60px 80px;
    
}

.page-banner h2{
    color: #ffffff;
    font-size: 20px;
}

.page-banner p{
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    width: 50%;
    padding: 20px 0;
}

.page-banner img{
    width: 70px;
    padding-top: 20px;
}


@media (max-width: 1200px) {
    .page-banner{
        padding: 30px;
    }

    .page-banner p{
        width: 90%;
    }

}

@media (max-width: 768px) {}

@media (max-width: 480px) {}