.contact-info{
    width: 100%;
    padding: 60px 80px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-bottom: 0px;
}

.contact-info-box{
    width: 30%;
    padding: 40px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../images/ivory-bg.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.contact-info-box i{
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    padding: 15px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.contact-info-box p{
    font-size: 16px;
    color: #ffffff;
    text-align: center;
}


.contact-form{
    width: 100%;
    padding: 60px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form{
    width: 100%;
    padding: 80px 40px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../images/ivory-bg.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.form input,textarea{
    outline: none;
    padding: 15px;
    background: rgba(255, 255, 255, 0.2); 
    border-radius: 5px;
    backdrop-filter: blur(10px); 
    -webkit-backdrop-filter: blur(10px); 
    border: none;
    color: #ffffff;
    width: 80%;
}

.form input::placeholder{
    color: #ffffff;
}

.form textarea::placeholder{
    color: #ffffff;
}


.form h2{
    color: #ffffff;
    font-size: 20px;
    text-align: center;
}

.form img{
    width: 100px;
}


.map{
    width: 100%;
}

.map iframe{
    width: 100%;
    height: 500px;
    /* filter: sepia(90%) saturate(150%) hue-rotate(10deg) brightness(0.9) contrast(1.2); */
}





@media (max-width: 1050px) {
    .contact-info{
        flex-wrap: wrap;
        gap: 40px;
        justify-content: center;
    }
    .contact-info-box{
        width: 45%;
    }


}

@media (max-width: 768px) {
    
    .contact-info{
        flex-direction: column;
        padding: 60px 30px;
        gap: 40px;
    }

    .contact-info-box{
        width: 100%;
    }

    .contact-form{
        padding: 30px;
    }

    .form{
        padding: 40px 20px;
    }
}

@media (max-width: 480px) {

}