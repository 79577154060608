.single-treatment-container{
    width: 100vw;
    padding: 50px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.single-treatment-container .main-img{
    width: 70%;
    height: 400px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-left: 5px solid #ff0000;
    border-bottom: 5px solid #ff0000;
    border-radius: 10px;
}

.single-treatment-container h1{
    font-size: 30px;
    color: #000000;
    text-align: center;
}

.single-treatment-container p{
    font-size: 14px;
    text-align: center;
    color: #000000;
    width: 70%;
}

.single-treatment-container h2{
    font-size: 20px;
    color: #ff0000;
    text-align: center;
}


.line-treatment{
    width: 100px;
    padding: 0;
}


@media (max-width: 1200px) {


}

@media (max-width: 768px) {
    .single-treatment-container{
        padding: 60px 30px;
    }
    .single-treatment-container .main-img{
        width: 100%;
    }
    .single-treatment-container p{
        width: 90%;
    }
}

@media (max-width: 480px) {

}