.treatM{
    width: 22%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
}

.treatM img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.treatM .treatment-desc{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
}

.treatM .treatment-desc h3{
    font-size: 16px;
    font-weight: bold;
    color: #ff0000;
}

.treatM .treatment-desc img{
    width: 100px;
}

.treatM .treatment-desc p{
    font-size: 14px;
    text-align: center;
    color: #000000;
}

.treatM .treatment-desc button{
    background-color: transparent;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 5px;
    border: none;
    
    font-size: 12px;
    font-weight: bold;
}

.treatM .treatment-desc a{
    text-decoration: none;
    color: #000000;
}


@media (max-width: 1200px) {
    .treatM{
        width: 30%;
    }
}


@media (max-width: 768px) {

    .treatM{
        width: 100%;
    }

}


@media (max-width: 964px) {
    .treatM{
        width: 45%;
    }
}

@media (max-width: 480px) {

    .treatM{
        width: 100%;
    }
}