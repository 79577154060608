.casesContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 60px;
    padding: 60px 80px;
}

.casesContainer .case{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    background-color: #ffffff;
    border-right: 5px solid red;

}

.casesContainer .case .case-img{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
   
}

.casesContainer .case .case-img img{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.casesContainer .case .case-desc{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 40px;
}

.casesContainer .case .case-desc h3{
    font-size: 24px;
}

.casesContainer .case .case-desc p{
    font-size: 16px;
}



@media (max-width: 1200px) {

}

@media (max-width: 768px) {

    .casesContainer{
        flex-direction: column;
        padding: 60px 30px;
    }

    .casesContainer .case{
        flex-direction: column;
        border: none;
    }

    .casesContainer .case .case-img{
        width: 100%;
    }

    .casesContainer .case .case-img img{
        height: 300px;
    }

    .casesContainer .case .case-desc{
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    .casesContainer .case .case-desc h3{
        text-align: center;
    }

    .casesContainer .case .case-desc p{
        text-align: center;
    }
}

@media (max-width: 480px) {}