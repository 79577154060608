@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    max-width: 100%;
}

body {
    font-size: 16px;
    
  }

  a{
    text-decoration: none;
    color: #ffffff;
  }
  
  h1 {
    font-size: 2em;
    margin: 0.5em 0;
  }
  
  h2 {
    font-size: 1.5em;
    margin: 0.5em 0;
  }
  
  h3 {
    font-size: 1.2em;
    margin: 0.5em 0;
  }
  
  p {
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    line-height: 1.6;
    margin: 0.5em 0;
  }
   
  img {
    max-width: 100%;
    height: auto;
  }
  
  /* Media Queries */
  @media (min-width: 768px) {
    body {
      font-size: 18px;
    }

  
    h1 {
      font-size: 2.5em;
    }
  
    h2 {
      font-size: 1.75em;
    }
  
    h3 {
      font-size: 1.4em;
    }
  }
  
  @media (min-width: 1024px) {
    body {
      font-size: 20px;
    }
  
    h1 {
      font-size: 3em;
    }
  
    h2 {
      font-size: 2em;
    }
  
    h3 {
      font-size: 1.6em;
    }
  }
  