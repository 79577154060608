.about-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 40px 80px;
}

.aboutImg {
    width: 100%;
    border-top: 5px solid #ff0000;
    border-bottom: 5px solid #ff0000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 20px;
}

.about-us-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-us-info h1 {
    color: #ff0000;
    font-size: 30px;
}

.about-us-info h2 {
    color: #ff0000;
    font-size: 20px;
}

.about-us-info p {
    font-size: 16px;
    text-align: center;
    width: 70%;
}

.about-points {
    width: 100%;
    padding: 0px 80px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-bottom: 60px;
    flex-wrap: wrap;
}

.about-points-1 {
    width: 30%;
    padding: 40px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url('../../images/ivory-bg.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.about-points-1 h2 {
    font-size: 20px;
    color: #ffffff;
    text-align: center;
}

.about-points-1 p {
    font-size: 14px;
    color: #ffffff;
    text-align: center;
}


.about-points-1 img {
    width: 50%;
}


@media (max-width: 1200px) {

    .about-points{
        gap: 40px;
        justify-content: center;
    }
    .about-points-1{
        width: 45%;
    }
}


@media (max-width: 768px) {
    .about-us {
        padding: 30px;
    }

    .about-us-info h2 {
        text-align: center;
    }

    .about-us-info p {
        width: 100%;
    }

    .about-points{
        flex-direction: column;
        padding: 30px;
        gap: 60px;
    }

    .about-points-1{
        width: 100%;
    }
}

@media (max-width: 480px) {
    .about-us {
        padding: 30px;
    }

    .about-us-info h2 {
        text-align: center;
    }

    .about-us-info p {
        width: 100%;
    }

    .about-points{
        flex-direction: column;
        padding: 30px;
        gap: 60px;
    }

    .about-points-1{
        width: 100%;
    }
}