.header{
    width: 100%;
}

.topNavbar{
    display: flex;
    justify-content: space-between;
    background-color: #ff0000;
    padding: 10px 80px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.left-topNavbar{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 40px;
}

.left-topNavbar .iconInfo {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.left-topNavbar .iconInfo p, i{
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
}

.right-topNavbar{
    width: 10%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
}


.right-topNavbar button{
    background: linear-gradient(45deg, #f39c12, #f1c40f);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s;
}

.right-topNavbar i{
    border: 2px solid #ffffff;
    border-radius: 50%;
    padding: 6px;
    font-size: 10px;
    background-color: #ff0000;
}

.navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 10px 80px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.left-navbar{
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.left-navbar ul{
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.left-navbar ul li{
    list-style-type: none;
    font-size: 14px;
    font-weight: bold;
    color: #ff0000;
    
}

.left-navbar ul li a{
    text-decoration: none;
    color: #ff0000;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
}

.left-navbar ul li a:hover{
    border-bottom: 3px solid #ff0000;
}


.center-navbar .logo{
    width: 150px;
    border-radius: 50%;
}

.center-navbar .line{
    width: 100px;
    padding-top: 30px;
}

.center-navbar{
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}



.right-navbar{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
}

.right-navbar button{
    padding: 15px 20px;
    background-color: #ff0000;
    border-radius: 5px;
    color: #ffffff;
    border: none;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: 2px solid #be912c;
    border-radius: 50px;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.right-navbar button:hover{
    transform: scale(1.05);
}

.right-navbar button a{
    text-decoration: none;
    color: #ffffff;
}


.hamburger {
    display: none;
    cursor: pointer;
    font-size: 25px;
    color: #000000;
}

.close{
    display: none;
}

.open{
    display: flex;
}

.mobileTop{
    display: none;
    padding: 10px 30px;
    background-color: #ff0000;
}

.mobileTop .phone{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.mobileTop .phone i{
    color: #ffffff;
    font-size: 10px;
    padding: 8px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}

.mobileTop .phone p{
    color: #ffffff;
    font-size: 14px;
}

.mobileTop .icons{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}


.mobileTop .icons i{
    color: #ffffff;
    font-size: 10px;
    padding: 8px;
    border: 2px solid #ffffff;
    border-radius: 50%;
}



@media (max-width: 1369px) {
    .center-navbar .line{
        display: none;
    }
}

@media (max-width: 1200px) {
    .right-navbar{
        display: none;
    }

    .left-navbar{
        width: 80%;
        justify-content: right;
    }
    .center-navbar{
        width: 20%;
    }

    .navbar{
        justify-content: space-between;
    }

    .center-navbar .line{
        display: none;
    }

    .topNavbar .left-topNavbar .iconInfoPhone{
        display: none;
    }
    .mobileTop{
        display: none;
    }

}


@media (max-width: 963px) {

    .topNavbar .right-topNavbar{
        display: none;
    }

    .topNavbar .left-topNavbar{
        width: 100%;
        justify-content: center;
    }

    .navbar{
        flex-direction: column;
        padding: 20px;
        gap: 20px;
    }

    .navbar .center-navbar{
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .navbar .left-navbar{
        width: 100%;
        align-items: center;
        justify-content: center;
    }
}


@media (max-width: 768px) {

    .navbar{
        padding: 10px 30px;
        flex-direction: row;
    }
    .topNavbar{
        display: none;
    }

    .left-navbar{
        display: none;
    }

    .right-navbar{
        display: none;
    }

    .hamburger{
        display: flex;
        align-items: center;
        justify-content: center;
    
    }

    .hamburger i{
        font-size: 30px;
        color: #ff0000;
    }

    .navbar .center-navbar{
        width: 70%;
        justify-content: start;
    }

    .mobileMenu{
        height: 100%;
        flex-direction: column;
        background-color: #ff0000;
        position: fixed;
        top: 0;
        left: 0;
        width: 60vw;
        gap: 20px;
        list-style: none;
        padding: 20px;
        color: #ffffff;
        z-index: 99999999;
    }

    .mobileMenu li a{
        color: #ffffff;
        font-weight: 500;
    }

    .mobileTop{
        display: flex;
    }
    
}


@media (max-width: 480px) {
   
}