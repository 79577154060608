.footer{
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../images/ivory-bg.jpg');
    background-position: center;
    background-size: cover;
}

.main-footer{
    display: flex;
    padding: 60px 80px;
    /* align-items: center; */
    justify-content: space-between;
}

.main-footer .box1{
    width: 15%;
}

.main-footer .box1 h2{
    color: #ff0000;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}

.main-footer .box1 li{
    list-style-type: none;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 0;
}



.main-footer .box3{
    width: 50%;
    padding-right: 60px;
}

.main-footer .box3 h2{
    color: #ff0000;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}

.main-footer .box3 li{
    list-style-type: none;
    
}

.main-footer .box3 .iconInfo{
   padding-bottom: 20px;
    /* border-bottom: 1px solid #ffffff62; */
    display: flex;
    gap: 20px;
    align-items: center;
}

.main-footer .box3 .iconInfo i{
    color: #ff0000;
    font-size: 14px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 50%;
}


.main-footer .box3 .iconInfo p{
    color: #ffffff;
    font-size: 14px;
}

.main-footer .box4{
    width: 20%;
}

.main-footer .box4 h2{
    color: #ff0000;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}


.main-footer .box4 iframe{
    height: 200px;
    border-radius: 5px;
    border: 2px solid #ff0000;
}


.bottom-footer{
    padding: 20px 80px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom-footer h3{
    font-size: 14px;
    color: #ff0000;
}


@media (max-width: 1200px) {

}


@media (max-width: 768px) {
    .main-footer{
        padding: 60px 30px;
        flex-direction: column;
        gap: 40px;
    }
    .main-footer .box1{
        width: 100%;
    }

    .main-footer .box3{
        width: 100%;
    }

    .main-footer .box4{
        width: 100%;
    }

    .main-footer .box3 .iconInfo{
        align-items: start;
    }

    .bottom-footer{
        flex-direction: column;
        padding: 20px 30px;
    }

    .bottom-footer h3{
        text-align: center;
    }

    .main-footer .box4 iframe{
        height: 400px;
        width: 100%;
    }
}



@media (max-width: 480px) {


}